/**
 * Theme global.js
 */

function init()
{
    'use strict';

    //
}

document.addEventListener('DOMContentLoaded', init);

(function ($) {
    $(document).ready(function () {
        AOS.init({
            offset: 100,
            delay: 100,
            duration: 400,
            anchorPlacement: 'top-center',
            once: true
        });
    });
})(jQuery);
